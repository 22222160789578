<template>
  <div >
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <div>
        <div class="top_nav"><span></span>淋巴细胞绝对计数(LYC)
        </div>
        <div class="formBody">
        
          <van-form  @submit="convert(currentUnit)">
            <div class="form_title">国际单位（推荐）</div>

            <div class="form_card" v-for="item in internationalUnits" :key="item" >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input autocomplete="off" step="0.0001" type="number" class="form_card_input_NEW" :placeholder="item" v-model.number="form[item]"  @blur="setCurrentUnit(item)" >
            </div>

            <div class="form_title">常用单位</div>
            <div class="form_card" v-for="item in commonUnits" :key="item" >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input autocomplete="off" step="0.0001" type="number" class="form_card_input_NEW" :placeholder="item" v-model.number="form[item]" @blur="setCurrentUnit(item)" >
            </div>
            <div style="margin: 16px;display: flex;justify-content: center;">
              <van-button style="width:35%;margin-right: 15px" block type="info" native-type="submit">计算</van-button>
              <van-button style="width:35%;border-color: #0091FB;color: #0091FB" block   @click="backBtn">重置</van-button>
            </div>

          </van-form>
        </div>
      </div>

      <div style=" margin-top: 15px;" v-if="tableData.explain && tableData.explain.length > 0">
        <div v-for="(item,index) in tableData.explain" :key="index" class="StandardDescription">
          <div class="top_nav"><span></span>{{ item.name|| item.title}}</div>
          <div style="padding: 2px 10px;background: white" >
            <span v-html="item.setText||item.value">
            </span>
          </div>
        </div>
      </div>
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>
  </div>
</template>
<script>
import FormulaBanner from "@/components/FormulaBanner.vue"; //收藏顶部组件
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula} from "@/service/BodyMassIndex_api";
import {postDataBrowse} from "@/service/api";  //埋点 先不传
import FormulaReferences from "@/components/template1/FormulaReferences.vue";
export default {
  name: "untilTest",
  components: {FormulaBanner,FormulaReferences},
  data(){
    return{
      title:'淋巴细胞绝对计数(LYC)',
      formulaCode:'RHSJNDHT',
      countText: '',
      form:{},
      tableData: {},
      internationalUnits: ['10^9/L','g/L','gpt/L','cells/L'], //国家单位
      commonUnits: ['10^3/μL','10^3/mm^3','K/μL','K/mm^3','cells/μL','cells/mm^3'], //常用单位
      currentUnit:'10^9/L',
      conversionFactors: {
        '10^9/L':1,
        'g/L':1,
        'gpt/L':1,
        'cells/L':1e9,
        '10^3/μL':1,
        '10^3/mm^3':1,
        'K/μL':1,
        'K/mm^3':1,
        'cells/μL':1e3,
        'cells/mm^3':1e3,
      }
    }
  },
  mounted() {
    this.test()
    this.getTableData()
  },
  methods:{
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      //点击埋点
      const data={
        "type":"yxgs_dwhs",//index表示在首页列表中点击，mix_index表示在专题列表中点击
        "contentId":Number(this.$route.query.id),//为vod时，根据contentId增加浏览量
        "contentType":'YXGS_DWHS',
        "location":this.$route.query.location,
        "channel":this.$route.query.channel,
        "os":arrays.os,
        "memberId":this.$route.query.memberId,
      }
      await postDataBrowse(data)
      await setById(arrays)
    },
    setCurrentUnit(unit) {
      if (this.form[unit]===null || this.form[unit]===undefined||this.form[unit]===''){
        return
      }
      this.currentUnit = unit;
      this.convert(unit)
    },
    // 点击计算执行
    convert(fromUnit) {
      const value = parseFloat(this.form[fromUnit]);
      switch (fromUnit) {
        case '10^9/L':
        this.form['g/L'] = this.applyPrecision(value * this.conversionFactors[ '10^9/L']);
        this.form['gpt/L'] = this.applyPrecision(value * this.conversionFactors[ '10^9/L']);
        this.form['10^3/μL'] = this.applyPrecision(value * this.conversionFactors[ '10^9/L']);
        this.form['10^3/mm^3'] = this.applyPrecision(value * this.conversionFactors[ '10^9/L']);
        this.form['K/μL'] = this.applyPrecision(value * this.conversionFactors[ '10^9/L']);
        this.form['K/mm^3'] = this.applyPrecision(value * this.conversionFactors[ '10^9/L']);
        this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
        this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);
        break;
        case 'g/L':
        this.form['10^9/L'] = this.applyPrecision(value*this.conversionFactors[ 'g/L']);
        this.form['gpt/L'] = this.applyPrecision(value*this.conversionFactors[ 'g/L']);
        this.form['10^3/μL'] = this.applyPrecision(value*this.conversionFactors[ 'g/L']);
        this.form['10^3/mm^3'] = this.applyPrecision(value*this.conversionFactors[ 'g/L']);
        this.form['K/μL'] = this.applyPrecision(value*this.conversionFactors[ 'g/L']);
        this.form['K/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'g/L']);
        this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
        this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);    
        break;
        case "gpt/L":
        this.form['10^9/L'] = this.applyPrecision(value*this.conversionFactors[ 'gpt/L']);
        this.form['g/L'] = this.applyPrecision(value * this.conversionFactors[ 'gpt/L']);
        this.form['10^3/μL'] = this.applyPrecision(value*this.conversionFactors[ 'gpt/L']);
        this.form['10^3/mm^3'] = this.applyPrecision(value*this.conversionFactors[ 'gpt/L']);
        this.form['K/μL'] = this.applyPrecision(value*this.conversionFactors[ 'gpt/L']);
        this.form['K/mm^3'] = this.applyPrecision(value * this.conversionFactors['gpt/L']);
        this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
        this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);  
        break;
        case "cells/L":
        this.form['10^9/L'] = this.applyPrecision(value * 1/this.conversionFactors[ 'cells/L']);
        this.form['g/L'] = this.applyPrecision(value  * 1/this.conversionFactors[ 'cells/L']);
        this.form['gpt/L'] = this.applyPrecision(value * 1/this.conversionFactors[ 'cells/L']);
        this.form['10^3/μL'] = this.applyPrecision(value  * 1/this.conversionFactors[ 'cells/L']);
        this.form['10^3/mm^3'] = this.applyPrecision(value* 1/this.conversionFactors[ 'cells/L']);
        this.form['K/μL'] = this.applyPrecision(value* 1/this.conversionFactors[ 'cells/L']);
        this.form['K/mm^3'] = this.applyPrecision(value * 1/this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * 1/1e6);
        this.form['cells/mm^3'] = this.applyPrecision(value * 1/1e6);  
        break;
        case "10^3/μL":
         this.form['10^9/L'] = this.applyPrecision(value*this.conversionFactors[ "10^3/μL"]);
         this.form['g/L'] = this.applyPrecision(value*this.conversionFactors[ "10^3/μL"]);
         this.form['gpt/L'] = this.applyPrecision(value*this.conversionFactors[ "10^3/μL"]);
         this.form['10^3/mm^3']= this.applyPrecision(value*this.conversionFactors[ "10^3/μL"]);
         this.form['K/μL']= this.applyPrecision(value*this.conversionFactors[ "10^3/μL"]);
         this.form['K/mm^3']= this.applyPrecision(value*this.conversionFactors[ "10^3/μL"]);
         this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
        this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);
         break;
        case "10^3/mm^3":
         this.form['10^9/L'] = this.applyPrecision(value*this.conversionFactors[ "10^3/mm^3"]);
         this.form['g/L'] = this.applyPrecision(value*this.conversionFactors[ "10^3/mm^3"]);
         this.form['gpt/L'] = this.applyPrecision(value*this.conversionFactors[ "10^3/mm^3"]);
         this.form['10^3/μL'] = this.applyPrecision(value*this.conversionFactors[ "10^3/mm^3"]);
         this.form['K/μL'] = this.applyPrecision(value*this.conversionFactors[ "10^3/mm^3"]);
         this.form['K/mm^3'] = this.applyPrecision(value*this.conversionFactors[ "10^3/mm^3"]);
         this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
         this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
         this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);
         break;
        case 'K/μL':
        this.form['10^9/L'] = this.applyPrecision(value*this.conversionFactors[ "K/μL"]);
        this.form['g/L'] = this.applyPrecision(value*this.conversionFactors[ "K/μL"]);
        this.form['gpt/L'] = this.applyPrecision(value*this.conversionFactors[ "K/μL"]);
        this.form['10^3/μL'] =this.applyPrecision(value*this.conversionFactors[ "K/μL"]);
        this.form['10^3/mm^3']= this.applyPrecision(value*this.conversionFactors[ "K/μL"]);
        this.form['K/mm^3'] =this.applyPrecision(value*this.conversionFactors[ "K/μL"]);
         this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
        this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);
        break;
        case 'K/mm^3':
        this.form['10^9/L'] = this.applyPrecision(value*this.conversionFactors['K/mm^3']);
        this.form['g/L'] = this.applyPrecision(value*this.conversionFactors[ 'K/mm^3']);
        this.form['gpt/L'] = this.applyPrecision(value*this.conversionFactors[ 'K/mm^3']);
        this.form['10^3/μL'] =this.applyPrecision(value*this.conversionFactors[ 'K/mm^3']);
        this.form['10^3/mm^3']= this.applyPrecision(value*this.conversionFactors[ 'K/mm^3']);
        this.form['K/μL'] = this.applyPrecision(value*this.conversionFactors[ 'K/mm^3']);
        this.form['cells/L'] = this.applyPrecision(value * this.conversionFactors[ 'cells/L']);
        this.form['cells/μL'] = this.applyPrecision(value * this.conversionFactors[ 'cells/μL']);
        this.form['cells/mm^3'] = this.applyPrecision(value * this.conversionFactors[ 'cells/mm^3']);
        break;
        case "cells/μL":
        this.form['10^9/L'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['g/L']  =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['gpt/L']  =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['10^3/μL']  =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['10^3/mm^3'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['K/μL'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['K/mm^3'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/μL']);
        this.form['cells/L'] = this.applyPrecision(value * 1e6);
        this.form['cells/mm^3'] = this.applyPrecision(value * 1);
        break;
        case "cells/mm^3":
        this.form['10^9/L'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['g/L']  =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['gpt/L']  =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['10^3/μL']  =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['10^3/mm^3'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['K/μL'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['K/mm^3'] =this.applyPrecision(value * 1/this.conversionFactors[ 'cells/mm^3']);
        this.form['cells/L'] = this.applyPrecision(value * 1e6);
        this.form['cells/μL'] = this.applyPrecision(value * 1);
        break;
        default:
        break;
      }
    },
    applyPrecision(result) {
      // 转换为浮点数
      const floatValue = parseFloat(result);
      // 如果是浮点数且小数位数超过四位则保留四位小数，否则返回原始结果
      if (!isNaN(floatValue) && floatValue % 1 !== 0) { // 检查是否有小数
        const roundedValue = floatValue.toFixed(4); // 四舍五入保留四位小数
        return parseFloat(roundedValue).toString(); // 转回字符串
      }
      return floatValue.toString(); // 返回原始结果字符串
    },
    backBtn(){
      this.form={}
    },
    // 获取说明
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'RHSJNDHT',
      }
      const res = await getFormula(data.channel,data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
      }
    },
  },
}
</script>



<style scoped lang="scss">

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: auto;
  min-height: 100vh;
  font-size: 16px;
  padding-bottom: 20px;
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding:10px;

    .form_title{
      padding: 5px 8px;
      background: #E8F5FF;
    }
    .form_card{
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      margin: 10px ;
      align-items: center;
      .form_card_input{
        border-radius: 3px;
        min-height: 25px;
        border: 1px solid #E3E3E3;
      }
    }



    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>